@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");
html {
  font-size: 16px !important;
}

@media (max-width: 991px) {
  html {
    font-size: 14px !important;
  }
}

body {
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: 0.2s !important;
}

strong,
.bold {
  font-weight: 700 !important;
}

@media (max-width: 600px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (max-height: 650px) and (min-width: 991px) {
  .home {
    padding-top: calc(90px + 25px) !important;
  }
  .home .titulo {
    font-size: 22px;
  }
  .home .line-top {
    line-height: 1;
    margin-bottom: -20px;
  }
}

.lato {
  font-family: "Lato", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.lato-italic {
  font-family: "Lato", sans-serif !important;
  font-weight: 400 !important;
  font-style: italic !important;
}

.lato-bold {
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  font-style: normal !important;
}

.lato-heavy,
.btn-primary,
.btn-default {
  font-family: "Lato", sans-serif !important;
  font-weight: 900 !important;
  font-style: normal !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.montserrat-semibold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.bg-gray {
  background: #e8e8e8 !important;
}

.bg-orange {
  background-color: #e86400 !important;
}

.bg-brown {
  background-color: #c24b26 !important;
}

.bg-yellow {
  background-color: #ffec00 !important;
}

.bg-yellow-dark {
  background-color: #e4a60e !important;
}

.gray {
  color: #e8e8e8 !important;
}

.white {
  color: #fff !important;
}

.green {
  color: #026836 !important;
}

.orange {
  color: #e86400 !important;
}

.orange:hover,
.orange:focus {
  color: #e86400 !important;
}

.red {
  color: #d73100 !important;
}

.red:hover,
.red:focus {
  color: #d73100 !important;
}

.brown {
  color: #c24b26 !important;
}

.yellow {
  color: #ffec00 !important;
}

.yellow-dark {
  color: #e4a60e !important;
}

.yellow-dark:hover,
.yellow-dark:focus {
  color: #e4a60e !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.ml-2 {
  margin-left: 10px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mt-6 {
  margin-top: 30px;
}

.mb-6 {
  margin-bottom: 30px;
}

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-4 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-b {
  display: block;
}

.v-n {
  visibility: hidden;
}

.f-n {
  float: none;
}

.align-top {
  vertical-align: top;
}

.outline {
  border: 1px solid #f09;
}

.full-width {
  width: 100%;
}

.header {
  background-image: url("../img/bar.png");
  z-index: 99;
  background-size: cover;
  background-position: center;
  top: 0px;
  position: sticky;
  position: -webkit-sticky;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.inline-block {
  display: inline-block;
}

.rotate-90 {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: top;
  transform-origin: top;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.lh-0 {
  line-height: 0;
}

.vh-100 {
  height: 100vh;
  max-height: 720px;
}

@media (max-width: 767px) {
  .vh-100 {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    height: auto !important;
  }
}

.ls-10 {
  letter-spacing: 10pt;
  font-size: 12px;
}

@media (max-width: 767px) {
  .ls-10 {
    letter-spacing: 5pt;
    font-size: 10px;
  }
}

.ls-5 {
  letter-spacing: 5pt;
  font-size: 16px;
}

@media (max-width: 767px) {
  .ls-5 {
    letter-spacing: 5pt;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .top-bar {
    padding-top: 6px;
    padding-bottom: 5px;
  }
}

.top-bar .close-bar:hover {
  opacity: 0.8;
}

a:focus {
  outline: none;
}

.header .navbar {
  z-index: 100;
  border: none;
  padding: 10px 50px;
  box-sizing: border-box;
  min-height: auto;
  border-radius: 0px 0px 15px 15px;
  background: rgba(215, 49, 0, 0);
}

@media (max-width: 1199px) {
  .header .navbar {
    padding: 20px 30px;
  }
}

@media (max-width: 991px) {
  .header .navbar {
    padding: 10px 20px;
  }
}

.header .logo {
  padding-left: 50px;
  max-width: 370px;
  margin-top: 5px;
}

@media (max-width: 1199px) {
  .header .logo {
    padding-left: 0px;
    max-width: 280px;
  }
}

@media (max-width: 991px) {
  .header .logo {
    max-width: 180px;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .header .logo {
    max-width: 190px;
    margin-top: 0px;
  }
}

.navbar-toggle,
.navbar-toggle:focus,
.navbar-toggle:active {
  margin: 5px 0 0 0;
  color: #fff;
  border: none;
  background: transparent !important;
}

.navbar-toggle .icon-bar,
.navbar-toggle:focus .icon-bar,
.navbar-toggle:active .icon-bar {
  background: #fff !important;
}

.home {
  background: url("../img/bg-home.jpg") center no-repeat;
  background-size: cover;
  padding-top: 25px;
  padding-bottom: 70px;
  position: relative;
}

@media (max-width: 767px) {
  .home {
    background: url("../img/bg-home.jpg") center no-repeat;
    background-size: cover;
    padding-top: 25px;
  }
}

@media (max-width: 767px) {
  .home .sumario {
    font-size: 1.25rem;
  }
}

@media (max-width: 767px) {
  .home .titulo {
    font-size: 2.125rem;
  }
}

.home .line-top {
  font-size: 1.125rem;
  border-top: 2px solid #d73100;
  padding-right: 20px;
  display: inline-block;
  width: 80%;
}

@media (max-width: 767px) {
  .home .line-top {
    width: 100%;
  }
}

.home .line-bottom {
  font-size: 1.125rem;
  line-height: 1.2;
  border-bottom: 2px solid #c24b26;
  padding-right: 20px;
  display: inline-block;
  margin-bottom: -13px;
  padding-bottom: 15px;
  width: 80%;
}

.oferta-home p.especial {
  font-size: 1.125rem;
  margin: 0 0 -15px 0;
  padding: 0px 30px;
}

@media (max-width: 767px) {
  .oferta-home p.especial {
    margin: 0 0 -10px 0;
    padding: 0px 0px 0px 13px;
  }
}

.ribbon-green {
  width: 347px;
  height: 300px;
  position: relative;
  z-index: 5;
  margin: 0 0 -50px -30px;
  padding: 5px 30px;
}

@media (max-width: 991px) {
  .ribbon-green {
    height: 275px;
    margin: 0 0 -40px -30px;
  }
}

@media (max-width: 767px) {
  .ribbon-green {
    width: 297px;
    height: 270px;
    padding: 5px 20px;
    margin: 0 auto -20px auto;
  }
}

.ribbon-green .ahora {
  margin: 35px 0 15px 0;
}

@media (max-width: 767px) {
  .ribbon-green .ahora {
    margin: 40px 0 15px 0;
  }
}

.ribbon-green .price {
  margin: 0 0;
  line-height: 1;
}

.ribbon-green .price .symbol {
  font-size: 3.75rem;
  letter-spacing: -4pt;
  position: absolute;
  top: 0;
  left: 0;
}

.ribbon-green .price .number {
  font-size: 7.5rem;
  letter-spacing: -4pt;
  padding: 0 0 0 30px;
  margin: -15px 0 0 0;
  position: relative;
  display: inline-block;
}

.ribbon-green .price .decimal {
  font-size: 3.75rem;
  letter-spacing: -4pt;
  position: absolute;
  right: 50px;
  top: -10px;
}

.ribbon-green .price .currency {
  font-size: 3.75rem;
  letter-spacing: -4pt;
  margin-left: 5px;
}

.ribbon-green .line-decline {
  font-size: 1.5rem;
  line-height: 1;
  position: relative;
  margin: -5px 0 5px 0;
}

.ribbon-green .line-decline:after {
  position: absolute;
  content: "";
  background: #ffec00;
  width: 80%;
  height: 2px;
  top: 11px;
  left: 10%;
}

.ribbon-yellow {
  width: 307px;
  height: 115px;
  position: relative;
  z-index: 1;
  padding: 35px 20px 0px 20px;
  text-align: center;
}

@media (max-width: 991px) {
  .ribbon-yellow {
    padding: 20px 10px 0px 10px;
    width: 282px;
  }
}

@media (max-width: 767px) {
  .ribbon-yellow {
    width: 252px;
    padding: 30px 20px 0px 20px;
  }
}

@media (max-width: 767px) {
  .ribbon-yellow {
    background: #ffec00 !important;
    width: 100%;
    padding: 3px 15px 13px 15px;
    height: auto;
  }
}

.ribbon-yellow .underline {
  font-size: 20px;
  margin: 7px 0 7px;
}

@media (max-width: 767px) {
  .ribbon-yellow .underline {
    font-size: 16px;
  }
}

.ribbon-yellow .especial2 {
  font-size: 13px;
}

@media (max-width: 767px) {
  .ribbon-yellow .especial2 {
    font-size: 12px;
    line-height: 1.2;
  }
}

.underline:hover,
.underline:focus,
.underline:active {
  text-decoration: none;
}

.underline:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  background: #d73100;
  bottom: -3px;
  left: 0px;
}

@media (max-width: 767px) {
  .menu {
    width: 100%;
    border: none;
    box-shadow: none;
  }
}

.menu ul li:after {
  transition: 0.2s;
  border-bottom: 2px solid transparent;
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  bottom: 15px;
  left: 20px;
}

@media (max-width: 767px) {
  .menu ul li:after {
    display: none;
  }
}

.menu ul li:hover:after {
  border-bottom: 2px solid #ffec00;
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  bottom: 15px;
  left: 20px;
}

.menu ul li.btn-wrapper:hover:after {
  display: none;
}

.menu ul li.active a:after {
  border-bottom: 2px solid #ffec00;
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  bottom: 15px;
  left: 20px;
}

.menu ul li a {
  font-size: 0.9375rem;
}

@media (max-width: 991px) {
  .menu ul li a {
    padding: 20px 10px;
  }
}

@media (max-width: 767px) {
  .menu ul li a {
    padding: 10px 10px;
  }
}

.menu ul li a.btn {
  color: #087300;
  font-size: 20px;
  padding: 8px 10px;
  text-transform: capitalize;
  letter-spacing: -1pt;
  margin-top: 15px;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .menu ul li a.btn {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.menu ul li a.btn:hover,
.menu ul li a.btn:focus {
  color: #c24b26;
}

.ribbon-green {
  background: url("../img/trazado-2.png") 0 0 no-repeat;
  background-size: contain;
}

.ribbon-yellow {
  background: url("../img/trazado-home.png") 0 0 no-repeat;
  background-size: contain;
}

.benefits .top {
  background: #173617 url("../img/bg-iconos-top.jpg") center repeat-x;
  background-size: cover;
}

.benefits .content {
  background: url("../img/bg-iconos.jpg") center repeat-x;
  background-size: cover;
}

.features {
  padding: 40px 0px 80px;
}

@media (max-width: 767px) {
  .features {
    padding: 30px 0px 30px;
  }
}

@media (max-width: 767px) {
  .features li img {
    max-width: 140px;
  }
}

.features li:first-child,
.features li:last-child {
  padding-top: 20px;
}

@media (max-width: 767px) {
  .features li:first-child,
  .features li:last-child {
    padding-top: 0px;
  }
}

.features li:not(:last-child) {
  background: url("../img/linea-icon.png") top 40px right no-repeat;
}

@media (max-width: 767px) {
  .features li:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    background: url("../img/linea-icon-bottom.png") bottom center no-repeat;
  }
}

.features p {
  font-size: 1.625rem;
}

.monjes {
  background: url("../img/bg-monje.jpg") center no-repeat;
  background-size: cover;
  padding: 60px 0px;
}

.monjes .description {
  font-size: 1.375rem;
}

.monjes .description2 {
  font-size: 1.125rem;
}

@media (max-width: 767px) {
  .monjes .description2 {
    font-size: 1.375rem;
  }
}

.monjes .monjes-bottom {
  padding-top: 40px;
}

.monjes .monjes-bottom .title {
  font-size: 28px;
}

@media (max-width: 767px) {
  .monjes .monjes-bottom .title {
    font-size: 24px;
  }
}

.monjes .monjes-bottom .desc {
  margin: 0 0;
  font-size: 1.125rem;
}

.monjes .monjes-bottom img {
  margin-top: -30px;
}

.promo {
  background: url("../img/bg-promo.jpg") center no-repeat;
  background-size: cover;
  padding: 100px 0 0 0;
}

@media (max-width: 1199px) {
  .promo {
    padding: 0 0 0 0;
  }
}

@media (max-width: 767px) {
  .promo .frascos {
    margin: 50px -15px 0 -15px;
  }
}

.promo .title {
  background: url("../img/parentesis-envio-gratis.png") center no-repeat;
  background-size: contain;
  padding: 15px 25px;
  font-size: 2.625rem;
  text-shadow: 1px 1px 1px #888;
  margin-bottom: 3.125rem;
}

@media (max-width: 1199px) {
  .promo .title {
    font-size: 2.2rem;
  }
}

@media (max-width: 767px) {
  .promo .title {
    font-size: 1.7rem;
  }
}

.promo .envio-gratis {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .promo .envio-gratis {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.beneficios {
  background: #e8e8e8 url("../img/bg-piedras-top.png") top center no-repeat;
  background-size: 100% auto;
  padding-top: 70px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .beneficios .item {
    margin-bottom: 25px;
  }
}

.beneficios .icon {
  position: relative;
}

@media (max-width: 767px) {
  .beneficios .icon img {
    max-width: 90px;
    max-height: 90px;
  }
}

.beneficios .rotate-90 {
  text-align: left;
  line-height: 1;
  padding-left: 25px;
  position: absolute;
  white-space: nowrap;
  bottom: 60px;
}

.beneficios .rotate-90.right {
  right: -110px;
}

.beneficios .rotate-90.left {
  left: -120px;
}

.beneficios .rotate-90 img {
  position: absolute;
  left: 0;
}

.beneficios .space-top {
  margin-top: 170px;
}

@media (max-width: 991px) {
  .beneficios .space-top {
    margin-top: 0px;
  }
}

.beneficios .space-top-2 {
  margin-top: 70px;
}

@media (max-width: 991px) {
  .beneficios .space-top-2 {
    margin-top: 20px;
  }
}

.beneficios .space-bottom-2 {
  margin-bottom: 70px;
}

@media (max-width: 991px) {
  .beneficios .space-bottom-2 {
    margin-bottom: 20px;
  }
}

.beneficios .subtitle {
  font-size: 1.625rem;
}

.beneficios .desc {
  font-size: 1.125rem;
  margin-bottom: 90px;
}

@media (max-width: 767px) {
  .beneficios .desc {
    margin-bottom: 50px;
  }
}

.beneficios .container {
  background: url("../img/bg-piedras.jpg") 0 300px no-repeat;
}

.beneficios .container .row-last {
  background: url("../img/bg-piedras.jpg") right bottom;
  background-repeat: no-repeat;
}

.beneficios .items-container {
  top: 90px;
}

.beneficios .envio-gratis-2 {
  margin-left: auto;
  margin-right: auto;
}

.beneficios .bottommsg {
  margin: 40px 0px 50px;
  display: inline-block;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .beneficios .bottommsg {
    font-size: 26px;
    margin: 10px 0px 30px;
  }
}

.beneficios .bottommsg span {
  background: #e4a60e;
  padding: 0px 10px;
}

.beneficios:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url("../img/bg-piedras-bottom.png") bottom center no-repeat;
  background-size: 100% auto;
  left: 0;
  top: 0;
}

.main-title {
  font-size: 3.875rem;
  margin-bottom: 45px;
}

@media (max-width: 767px) {
  .main-title {
    font-size: 3rem;
    margin-bottom: 10px;
  }
}

.main-title:after {
  content: "";
  width: 30%;
  height: 3px;
  position: absolute;
  background: #c24b26;
  bottom: -10px;
  left: 35%;
}

.pictures {
  background-color: #f8a700;
  padding-top: 75px;
}

@media (max-width: 767px) {
  .pictures {
    padding-top: 15px;
  }
}

.pictures .content {
  background-color: #f39500;
}

@media (max-width: 767px) {
  .pictures .content {
    background: transparent;
  }
}

.pictures .icon-top {
  margin-top: -30px;
  margin-bottom: 45px;
}

.pictures .pics {
  background: #ed8000;
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .pictures .pics {
    background: transparent;
    padding-bottom: 15px;
  }
}

.pictures .pics .slick-list {
  margin: 0 -10px 0 -10px;
}

.pictures .pics:after {
  content: "";
  width: 198px;
  height: 100%;
  background: url("../img/bg-pics.png") 0 0 no-repeat;
  left: -198px;
  position: absolute;
  top: 0px;
  z-index: 5;
}

@media (max-width: 767px) {
  .pictures .pics:after {
    display: none;
  }
}

.pictures .pics:before {
  content: "";
  width: 87px;
  height: 58px;
  background: url("../img/bg-pics2.png") 0 0 no-repeat;
  right: 0px;
  position: absolute;
  bottom: 0px;
  z-index: 5;
}

@media (max-width: 767px) {
  .pictures .pics:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .slick-initialized .slick-slide {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.testimonials {
  background: url("../img/bg-bottom-2.jpg") top right no-repeat;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 70px;
}

@media (max-width: 767px) {
  .testimonials {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.testimonials .main-title:after {
  background: #e4a60e;
}

.testimonials .item p {
  line-height: 1.4;
}

@media (max-width: 767px) {
  .testimonials .item p {
    font-size: 16px;
  }
}

.testimonials .author:after {
  background: #e4a60e;
  bottom: -10px;
}

.testimonials .icon-bottom {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .testimonials .icon-bottom {
    margin-top: 30px;
    width: 60px;
  }
}

.footer {
  background: url("../img/bg-bottom-3.jpg") center no-repeat;
  background-size: cover;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .footer .call {
    max-width: 240px;
  }
}

.footer .parentesis {
  background: url("../img/parentesis-envio-gratis.png") center no-repeat;
  background-size: 100% auto;
  padding: 35px 25px;
}

@media (max-width: 767px) {
  .footer .parentesis {
    padding: 25px 0;
    display: inline-block;
    font-size: 24px;
    max-width: 300px;
  }
}

.footer .credits {
  font-size: 0.8125rem;
  color: #e8e8e8;
  letter-spacing: 1pt;
  opacity: 0.6;
}

.footer .credits a:hover,
.footer .credits a:focus {
  color: #e8e8e8;
}

.shadow {
  position: relative;
}

.shadow:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url("../img/sombra.png") top center no-repeat;
  background-size: 100% auto;
  left: 0;
  top: 0;
}

.gotop {
  position: absolute;
  top: -20px;
  right: 30px;
}

.menu-footer {
  border-top: 1px solid #e4a60e;
  border-bottom: 1px solid #e4a60e;
  padding: 7px 15px;
  margin-top: 35px;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .menu-footer {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.menu-footer li {
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 767px) {
  .menu-footer li {
    display: block;
    width: 100%;
  }
}

.menu-footer li:not(:last-child) {
  border-right: 1px solid #e4a60e;
}

@media (max-width: 767px) {
  .menu-footer li:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #e4a60e;
    margin: 0 0 10px 0;
  }
}

.menu-footer li a {
  font-size: 1.125rem;
}

@media (max-width: 767px) {
  .menu-footer li a {
    font-size: 18px;
    display: block;
    padding-bottom: 10px;
  }
}

.btn-primary {
  transition: 0s;
  padding: 3px 20px;
  border: 3px solid #ffec00 !important ;
  font-size: 1.625rem;
  background: #fffbcc !important;
  background: linear-gradient(to bottom, #fffbcc 0%, #ffec00 100%) !important;
  font-weight: bold !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active:focus {
  box-shadow: none !important;
  border: 3px solid #e6d400 !important;
  background: #fffbcc !important;
  outline: none !important;
  background: linear-gradient(to bottom, #fffbcc 0%, #e6d400 100%) !important;
}

.btn-default {
  transition: 0s !important;
  color: #ffec00 !important;
  padding: 4px 16px !important;
  border: 3px solid #026836 !important;
  font-size: 1.125rem !important;
  background: #96ae96 !important;
  background: linear-gradient(to bottom, #96ae96 0%, #026836 100%) !important;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active:focus {
  box-shadow: none !important;
  border: 3px solid #024f29 !important;
  background: #96ae96 !important;
  color: #ffec00 !important;
  outline: none !important;
  background: linear-gradient(to bottom, #96ae96 0%, #024f29 100%) !important;
}

.close-modal {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1rem;
  background: #f2f2f2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
  line-height: 1.3;
  border: none;
  z-index: 100;
}

.close-modal:hover {
  background-color: #e86400;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 991px) {
  .container {
    width: 100%;
  }
}

.rotate-hover {
  transition: 0.5s;
}

.rotate-hover:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.hover-action {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

.hover-action:hover {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

.pisos::before {
  position: absolute;
  content: "";
  background-image: url("../img/frascos-alivia.png");
  width: 600px;
  left: 90%;
  top: 150px;
  height: 400px;
  z-index: 99;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.pt-6 {
  margin-top: 120px;
}
.interstitial {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ead705;
  z-index: 99;
}
.interstitial p {
  margin: 0 16px 0 0;
  color: #386234;
}
@media (min-width: 767px) {
  .fixed {
    display: none;
  }
}

@media (max-width: 767px) {
  .fixed {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
  }
  .header {
    top: 53px;
  }
  .pt-6 {
    margin-top: 20px;
  }
  .interstitial {
    display: none;
  }
}
